<template>
  <timeline>
    <timeline-title v-show="isLoading">{{ $t('notifications.loading.label') }}
      <looping-rhombuses-spinner :rhombus-size="15" />
    </timeline-title>
    <timeline-title v-if="history.length === 0 && !isLoading">{{ $t('teams.tabs.history.no_events') }}</timeline-title>
    <timeline-item
      v-for="h in history"
      :hollow="true"
      :bg-color="getEventColor(h.type)"
      :key="h.id"
    >
      <h5>
        <strong>{{ $t('tables.headings.date') }}:</strong> {{ h.created_at | date }}
      </h5>
      <h6>
        <strong>{{ $t('tables.headings.status') }}:</strong> {{ getEventStatus(h.type) }}
      </h6>
      <h6 v-if="h.user">
        <strong>{{ $t('tables.headings.user') }}:</strong> {{ h.user.name }}
      </h6>
    </timeline-item>
  </timeline>
</template>
<script>
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline'
import LoopingRhombusesSpinner from 'epic-spinners/src/components/lib/LoopingRhombusesSpinner.vue'

export default {
  name: 'teams-history',
  components: {
    LoopingRhombusesSpinner,
    Timeline,
    TimelineItem,
    TimelineTitle,
  },
  props: {
    teamId: {
      required: false,
    },
    loading: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data () {
    return {
      history: [],
      isLoading: false,
    }
  },
  watch: {
    teamId (val) {
      if (Number.isInteger(val)) {
        this.updateData()
      }
    },
  },
  methods: {
    getEventColor (type) {
      const inEvents = [0, 2, 4]
      return inEvents.some(i => i === type) ? 'rgb(62, 200, 59)' : 'rgb(243, 47, 47)'
    },
    getEventStatus (type) {
      let translate = 'teams.tabs.history.unknown'
      switch (type) {
        case 0: translate = 'teams.tabs.history.user_enter'; break
        case 1: translate = 'teams.tabs.history.user_leave'; break
        case 2: translate = 'teams.tabs.history.leader_enter'; break
        case 3: translate = 'teams.tabs.history.leader_leave'; break
        case 4: translate = 'teams.tabs.history.team_renewed'; break
      }
      return this.$t(translate)
    },
    async updateData () {
      this.isLoading = true

      let response = false
      try {
        response = await this.$http.get('teams/history/' + this.teamId)
      } catch (e) {
        // console.log('History error', e)
        this.isLoading = false
        return
      }

      this.isLoading = false
      this.history = response.data.data
    },
  },
}
</script>
